/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { ConversationsNumber } from "types/Conversation";

type ConversationProviderProps = {
  conversationsNumber: ConversationsNumber;
  refreshConversationsNumber: () => Promise<ConversationsNumber>;
  isFetching: boolean;
};

export const ConversationContext = createContext<ConversationProviderProps>(
  {} as ConversationProviderProps
);

type Props = {
  children: React.ReactNode;
};

const defaultConversationsNumber: ConversationsNumber = {
  all: 0,
  opened: 0,
  waiting_answer: 0,
  price: 0,
  meeting: 0,
  rejected: 0,
  sold: 0,
};

export function ConversationProvider({ children }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [conversationsNumber, setConversationsNumber] =
    useState<ConversationsNumber>(defaultConversationsNumber);

  const refreshConversationsNumber = useCallback(async () => {
    try {
      setIsFetching(true);

      const res = await fetchJSON({
        url: `conversations/data/number`,
        method: "GET",
      });

      if (res) {
        setConversationsNumber(
          res
            ? {
                ...res,
              }
            : undefined
        );
      } else {
        setConversationsNumber(defaultConversationsNumber);
      }

      return res ?? false;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [setConversationsNumber]);

  useEffect(() => {
    const firstLoad = async () => {
      await refreshConversationsNumber();
    };

    firstLoad();
  }, [refreshConversationsNumber]);

  const value: ConversationProviderProps = useMemo(
    () => ({
      conversationsNumber,
      refreshConversationsNumber,
      isFetching,
    }),
    [conversationsNumber, isFetching, refreshConversationsNumber]
  );

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
}
