import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PageLoading from "common/components/PageLoading/PageLoading";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import formatPrice from "utils/formatPrice";
import dayjs from "dayjs";
import Button from "common/components/Button/Button";
import { Report } from "types/Report";
import UserCard from "components/UserCard/UserCard";
import rdvSecuriseImg from "../../../public/images/handshake-light.png";

function EmergencyDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { item, isFetching, updateItem, error }: any = useItem("reports", id, {
    populate: [
      "user",
      "user.avatar",
      "user.companyLogo",
      "author",
      "author.avatar",
      "author.companyLogo",
      "content",
      "content.author",
      "content.author.avatar",
      "content.author.companyLogo",
      "content.images",
      "conversation",
      "conversation.buyer",
      "conversation.buyer.avatar",
      "conversation.buyer.companyLogo",
      "conversation",
      "conversation.seller",
      "conversation.seller.avatar",
      "conversation.seller.companyLogo",
      "conversation.finalMeetingPlace",
      "conversation.content",
      "conversation.content.images",
      "conversation.content.author",
      "conversation.content.author.avatar",
      "conversation.content.author.companyLogo",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { content, conversation, description, state } = (item as Report) || {};

  const { finalPrice, finalMeetingPlace, startMeetingDate, endMeetingDate } =
    conversation ?? {};

  const contentFinal = content ?? conversation?.content;

  const canProcess = state === "unprocessed";
  const canUnprocess = state === "processed";
  const handleProcess = useCallback(() => {
    updateItem(id, { state: "processed" });
  }, [id, updateItem]);

  const handleUnprocess = useCallback(() => {
    updateItem(id, { state: "unprocessed" });
  }, [id, updateItem]);

  if (isFetching && !item) {
    return <PageLoading />;
  }

  return !item ? null : (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/emergencies"
        title={t("titlePages.emergencies")}
        TitleIcon={
          <div>
            <Icon
              name="ExclamationCircleIcon"
              className="w-16 h-16 mr-1 text-primary"
            />
          </div>
        }
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={state} />
          </div>
        }
        HeaderButtons={
          <div className="responsive-flex-lg gap-1 lg:justify-end w-full mt-3 lg:mt-0 items-start lg:items-center">
            {canProcess && (
              <Button
                title={t("actions.process")}
                type="valid"
                icon="CheckIcon"
                onClick={handleProcess}
                // compact
              />
            )}
            {canUnprocess && (
              <Button
                title={t("actions.unprocess")}
                type="error-line"
                icon="MdOutlineUnpublished"
                onClick={handleUnprocess}
                // compact
              />
            )}
          </div>
        }
      />

      <div className="white-box flex-col w-full mt-2">
        <SectionTitle
          title={t("reports.reason")}
          icon="BookmarkIcon"
          className="text-secondary"
        />
        <div className="responsive-flex py-4 flex-row">
          {description ? (
            description.split("\n").map((p: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`p-${index}`} className="mb-4">
                {p}
              </p>
            ))
          ) : (
            <p>{t("forms.noContent")}</p>
          )}
        </div>
      </div>
      <div className="responsive-flex gap-2 mt-2">
        <div className="white-box responsive-flex flex-col justify-between w-full lg:basis-1/2">
          {/* Column 1 */}
          <div className="flex flex-col gap-1">
            {!!finalPrice && (
              <>
                <SectionTitle
                  title={t("forms.priceValidated")}
                  icon="WalletIcon"
                  className="text-secondary"
                />
                <div className="responsive-flex flex-row">
                  <span className="font-bold text-xl pl-4">
                    {formatPrice(finalPrice)}
                  </span>
                </div>
              </>
            )}
            <SectionTitle
              title={t("forms.meetingTime")}
              icon="BookmarkIcon"
              className="text-secondary mt-2"
            />
            <div className="responsive-flex flex-row">
              <span className="font-bold text-xl py-1 pl-4">
                {startMeetingDate && endMeetingDate
                  ? `Le ${dayjs(startMeetingDate).format("LL")} de ${dayjs(
                      startMeetingDate
                    ).format("H:mm")} à ${dayjs(endMeetingDate).format("H:mm")}`
                  : t("conversations.noMeetingDate")}
              </span>
            </div>
            <SectionTitle
              title={t("forms.meetingPlace")}
              icon="BookmarkIcon"
              className="text-secondary mt-2"
            />
            {finalMeetingPlace ? (
              <div className="responsive-flex flex-col">
                {finalMeetingPlace.addressTitle && (
                  <div className="responsive-flex flex-row">
                    <span className="font-bold text-xl py-1 pl-4">
                      {finalMeetingPlace.addressTitle}
                    </span>
                  </div>
                )}
                {finalMeetingPlace.street && (
                  <div className="responsive-flex flex-row">
                    <span className="font-medium text-xl pl-4">
                      {`${finalMeetingPlace.street}`}
                    </span>
                  </div>
                )}
                {finalMeetingPlace.postalCode && finalMeetingPlace.city && (
                  <div className="responsive-flex flex-row">
                    <span className="font-medium text-xl pl-4">
                      {`${finalMeetingPlace.postalCode} ${finalMeetingPlace.city}`}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="responsive-flex flex-row">
                <span className="font-bold text-xl py-1 pl-4">
                  {t("conversations.noMeetingPlace")}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="responsive-flex gap-2 flex-col w-full lg:basis-1/2">
          {conversation?.buyer && (
            <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
              <UserCard user={conversation?.buyer} title={t("forms.buyer")} />
            </div>
          )}
          {conversation?.seller && (
            <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
              <UserCard user={conversation?.seller} title={t("forms.seller")} />
            </div>
          )}
        </div>
      </div>
      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Description */}
            <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-start">
              {/* Column 1 */}
              <div className="white-box responsive-flex gap-2 flex-col w-full">
                <div className="flex flex-row gap-4">
                  <div className="basis-1/3">
                    <SectionTitle
                      title={t("forms.title")}
                      icon="WalletIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {contentFinal?.title}
                      </span>
                    </div>
                  </div>
                  <div className="basis-1/3">
                    <SectionTitle
                      title={t("forms.price")}
                      icon="WalletIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {formatPrice(contentFinal?.price ?? 0)}
                      </span>
                    </div>
                  </div>
                  <div className="basis-1/3 text-center">
                    <div className="flex flex-row justify-end gap-4 text-[#504e70]">
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          contentFinal?.useSafeMeetingPlace
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="CheckBadgeSolid" className="w-9 h-9" />
                        </div>
                        RDV sécurisé
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          contentFinal?.canExchange
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <ImageComponent image={rdvSecuriseImg} />
                        </div>
                        Troc accepté
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          contentFinal?.isChapChap
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="FireIcon" className="w-9 h-9" />
                        </div>
                        Chap chap !
                      </div>
                    </div>
                  </div>
                </div>
                <SectionTitle
                  title={t("forms.description")}
                  icon="BookmarkIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex py-2 px-4 flex-col border rounded">
                  {contentFinal?.description ? (
                    contentFinal.description
                      .split("\n")
                      .map((p: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={`p-${index}`} className="mb-4">
                          {p}
                        </p>
                      ))
                  ) : (
                    <p>{t("forms.noContent")}</p>
                  )}
                </div>
                <SectionTitle
                  title={t("forms.address")}
                  icon="MapPinIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex flex-row">
                  <span className="text-md py-1 pl-4">
                    {contentFinal?.address}
                  </span>
                </div>
              </div>
            </div>
            {/* Images */}
            <div className="responsive-flex white-box">
              <div className="responsive-flex gap-4 flex-col w-full">
                <div className="responsive-flex gap-4 w-full flex-row flex-wrap justify-center">
                  {contentFinal?.images?.map((image, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`image-${index}`}
                      className="rounded-lg overflow-hidden aspect-square h-[250px] md:h-[180px]"
                    >
                      <ImageComponent
                        image={ImageFromStrapiMedia(image)?.uri}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmergencyDetails;
