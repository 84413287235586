import { Suspense } from "react";
import { AuthProvider } from "common/providers/AuthProvider";
import { LoadingProvider } from "common/providers/LoadingProvider";
import { AlertsProvider } from "common/providers/alerts";
import { FieldsProvider } from "provider/FieldsProvider";
import { ContentProvider } from "common/providers/ContentProvider";
import { ConversationProvider } from "common/providers/ConversationProvider";
import { SocketProvider } from "common/providers/SocketProvider";
import { EmergencyProvider } from "common/providers/EmergencyProvider";
import { ReportProvider } from "common/providers/ReportProvider";

type Props = {
  children: JSX.Element;
};

function AppProvider({ children }: Props) {
  return (
    <Suspense>
      <LoadingProvider>
        <SocketProvider>
          <AlertsProvider>
            <AuthProvider>
              <ContentProvider>
                <ConversationProvider>
                  <ReportProvider>
                    <EmergencyProvider>
                      <FieldsProvider>{children}</FieldsProvider>
                    </EmergencyProvider>
                  </ReportProvider>
                </ConversationProvider>
              </ContentProvider>
            </AuthProvider>
          </AlertsProvider>
        </SocketProvider>
      </LoadingProvider>
    </Suspense>
  );
}

export default AppProvider;
