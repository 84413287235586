import Login from "pages/Auth/Login";
import Page404 from "pages/Page404";
import Home from "pages/Home/Home";
import Users from "pages/Users/Users";
import User from "pages/Users/User";
import MyAccount from "pages/Users/MyAccount";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Partners from "pages/Partners/Partners";
import Contents from "pages/Content/Contents";
import ContentDetails from "pages/Content/ContentDetails";
import Categories from "pages/Category/Categories";
import Category from "pages/Category/Category";
import Reports from "pages/Report/Reports";
import ReportDetails from "pages/Report/ReportDetails";
import Fields from "pages/Fields/Fields";
import Field from "pages/Fields/Field";
import Content from "pages/Content/Content";
import Conversations from "pages/Conversations/Conversations";
import Conversation from "pages/Conversations/Conversation";
import Partner from "pages/Partners/Partner";
import Reviews from "pages/Reviews/Reviews";
import ReviewDetails from "pages/Reviews/ReviewDetails";
import ConversationDetails from "pages/Conversations/ConversationDetail";
import UserVerifications from "pages/UserVerifications/UserVerifications";
import UserVerificationDetails from "pages/UserVerifications/UserVerificationDetails";
import Emergencies from "pages/Emergencies/Emergencies";
import EmergencyDetails from "pages/Emergencies/EmergencyDetails";

const managers = ["Admin"];
const pros = ["Admin", "Authenticated"];

const routes = [
  { path: "/", roles: "all", Component: Home },
  { path: "/emergencies", roles: managers, Component: Emergencies },
  { path: "/emergencies/:id", roles: managers, Component: EmergencyDetails },
  { path: "/reports", roles: managers, Component: Reports },
  { path: "/reports/:id", roles: managers, Component: ReportDetails },
  { path: "/reviews", roles: managers, Component: Reviews },
  { path: "/reviews/:id", roles: managers, Component: ReviewDetails },
  { path: "/partners", roles: managers, Component: Partners },
  { path: "/partners/add", roles: managers, Component: Partner },
  { path: "/partners/edit/:id", roles: managers, Component: Partner },
  { path: "/categories", roles: managers, Component: Categories },
  { path: "/categories/add", roles: managers, Component: Category },
  { path: "/categories/edit/:id", roles: managers, Component: Category },
  { path: "/fields", roles: managers, Component: Fields },
  { path: "/fields/add", roles: managers, Component: Field },
  { path: "/fields/edit/:id", roles: managers, Component: Field },
  { path: "/contents", roles: managers, Component: Contents },
  { path: "/contents/add", roles: managers, Component: Content },
  { path: "/contents/:id", roles: managers, Component: ContentDetails },
  { path: "/contents/edit/:id", roles: managers, Component: Content },
  { path: "/conversations", roles: managers, Component: Conversations },
  {
    path: "/conversations/:id",
    roles: managers,
    Component: ConversationDetails,
  },
  {
    path: "/conversations/edit/:id",
    roles: managers,
    Component: Conversation,
  },
  { path: "/users", roles: managers, Component: Users },
  { path: "/users/add", roles: managers, Component: User },
  { path: "/users/edit/:id", roles: managers, Component: User },
  { path: "/my-account", roles: pros, Component: MyAccount },
  { path: "/dashboard", roles: "all", Component: Home },
  {
    path: "/user-verifications",
    roles: managers,
    Component: UserVerifications,
  },
  {
    path: "/user-verifications/:id",
    roles: managers,
    Component: UserVerificationDetails,
  },
  { path: "*", roles: "all", exact: false, Component: Page404 },
];

export const loginRoutes = [
  { path: "/login", roles: "all", Component: Login },
  { path: "/forgot-password", roles: "all", Component: ForgotPassword },
  { path: "/reset-password", roles: "all", Component: ResetPassword },
  { path: "*", roles: "all", Component: Login },
];

export default routes;
