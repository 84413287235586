// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Partner as PartnerType } from "types/Partner";
import PartnerForm from "./PartnerForm";

function Partner() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<PartnerType>("meeting-places", id, {
      populate: [
        "images",
        "reviews",
        "reviews.onUser",
        "reviews.onUser.avatar",
        "reviews.author",
        "reviews.author.avatar",
        "reviews.conversation",
        "reviews.conversation.content",
      ],
    });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/partners");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (values: any) => {
      const newValues = {
        ...values,
        lowertitle: values.title?.toLowerCase(),
      };

      try {
        if (newValues.id) {
          await updateItem(newValues.id, newValues);
        } else {
          await saveItem(newValues);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <PartnerForm onSubmit={onSubmit} partner={item} />
  );
}

export default Partner;
