import {
  ArchiveBoxIcon,
  ArrowUpCircleIcon,
  BuildingStorefrontIcon,
  ChatBubbleLeftRightIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { PiFlagCheckeredFill } from "react-icons/pi";
import { RiAdminLine, RiUserLine, RiUserSettingsLine } from "react-icons/ri";
import { FaCheck, FaTimesCircle, FaRegCalendarCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { MdPriceCheck } from "react-icons/md";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface IStatus {
  status?: string | null;
  icon?: any;
  small?: boolean;
  useTooltip?: boolean;
}

function Status({ status, icon, small = false, useTooltip = false }: IStatus) {
  const { t } = useTranslation();
  if (!status) {
    return null;
  }

  let style = "bg-slate-500";
  let logoStyle = "w-5 h-5";
  let text = t(`status.${status}`);

  let Icon = icon;
  let noText = false;
  let baseStyle = small
    ? "py-1 px-2 h-6 text-[0.8rem]"
    : "py-1.5 px-4 text-[1rem]";

  // Status for contents

  if (status === "published") {
    style = "bg-green-500";
    Icon = CheckIcon;
  }

  if (status === "draft") {
    style = "bg-yellow-500";
    Icon = PencilIcon;
  }

  if (status === "submitted") {
    style = "bg-blue-500";
    Icon = ArrowUpCircleIcon;
  }

  if (status === "published") {
    style = "bg-green-500";
    Icon = CheckIcon;
  }

  if (status === "rejected") {
    style = "bg-red-500";
    Icon = ExclamationCircleIcon;
  }

  if (status === "negociated") {
    style = "bg-green-800";
    Icon = ChatBubbleLeftRightIcon;
  }

  if (status === "closed" || status === "sold") {
    style = "bg-slate-500";
    text = t("status.closed");
    Icon = ArchiveBoxIcon;
  }

  // Status for conversations

  if (status === "opened") {
    style = "bg-blue-500";
    Icon = PencilIcon;
  }

  if (status === "price_waiting_answer") {
    style = "bg-yellow-500";
    Icon = ArrowUpCircleIcon;
  }

  if (status === "price_validated") {
    style = "bg-green-500";
    Icon = MdPriceCheck;
  }

  if (status === "price_denied") {
    style = "bg-red-500";
    Icon = ExclamationCircleIcon;
  }

  if (status === "meeting_waiting_answer") {
    style = "bg-yellow-500";
    Icon = ArrowUpCircleIcon;
  }

  if (status === "meeting_validated") {
    style = "bg-green-500";
    Icon = FaRegCalendarCheck;
  }

  if (status === "meeting_denied") {
    style = "bg-red-500";
    Icon = ExclamationCircleIcon;
  }

  if (status === "on_place_both") {
    style = "bg-orange-500";
    Icon = PiFlagCheckeredFill;
  }

  if (status === "reviewed_both") {
    style = "bg-slate-500";
    Icon = CheckIcon;
  }

  if (status === "conversation_waiting_validation") {
    style = "bg-yellow-500";
    Icon = ArrowUpCircleIcon;
  }

  if (status === "conversation_validated") {
    style = "bg-green-500";
    Icon = CheckIcon;
  }

  if (status === "cancelled") {
    style = "bg-red-500";
    Icon = ExclamationCircleIcon;
  }

  // Status for reports

  if (status === "unprocessed") {
    style = "bg-yellow-500";
    text = t("status.unprocessed");
    Icon = ExclamationCircleIcon;
  }

  if (status === "processed") {
    style = "bg-green-500";
    text = t("status.processed");
    Icon = CheckIcon;
  }

  // Status for users

  if (status === "Authenticated") {
    style = "bg-blue-300";
    text = t("roles.Authenticated");
    Icon = RiUserLine;
  }

  if (status === "Admin") {
    style = "bg-blue-400";
    text = t("roles.Administrateur");
    Icon = RiUserSettingsLine;
  }

  if (status === "SuperAdmin") {
    style = "bg-blue-500";
    text = t("roles.SuperAdmin");
    Icon = RiAdminLine;
  }

  // Status for conversation type

  if (status === "onSeller") {
    style = "bg-blue-400";
    text = t("forms.seller");
    Icon = RiUserLine;
  }

  if (status === "onBuyer") {
    style = "bg-blue-500";
    text = t("forms.buyer");
    Icon = RiUserLine;
  }

  if (status === "onMeetingPlace") {
    style = "bg-orange-500";
    text = t("forms.onMeetingPlace");
    Icon = BuildingStorefrontIcon;
  }

  // status for user verifications

  if (status === "validated") {
    style = "bg-green-500";
    Icon = FaCheck;
  }

  if (status === "denied") {
    style = "bg-red-500";
    Icon = FaTimesCircle;
  }

  if (status === "pending") {
    style = "bg-yellow-500";
    Icon = PencilIcon;
  }

  // blocage utilisateur

  if (status === "blocked") {
    style = "bg-none";
    Icon = FaTimesCircle;
    logoStyle = "w-5 h-5 text-red-500";
    baseStyle = "py-1 px-2 h-8 text-[1rem]";
    noText = true;
  }

  if (status === "active") {
    style = "bg-none";
    Icon = FaCheck;
    logoStyle = "w-5 h-5 text-emerald-500";
    baseStyle = "py-1 px-2 h-8 text-[1rem]";
    noText = true;
  }

  return useTooltip ? (
    <TooltipWrapper
      className="mr-1 my-1"
      content={
        <span>
          {i18n.exists(`status.${status}Tooltip`)
            ? t(`status.${status}Tooltip`)
            : text}
        </span>
      }
      place="top"
    >
      <div
        className={`flex items-center gap-1 font-normal leading-0 text-center text-white rounded-full ${baseStyle} ${style} shrink-0`}
      >
        {Icon && <Icon title={text} className={`${logoStyle}`} />}
        {!noText ? ` ${text}` : null}
      </div>
    </TooltipWrapper>
  ) : (
    <div
      className={`flex items-center gap-1 font-normal leading-0 text-center text-white rounded-full ${baseStyle} ${style} shrink-0`}
    >
      {Icon && <Icon title={text} className={`${logoStyle}`} />}
      {!noText ? ` ${text}` : null}
    </div>
  );
}

export default Status;
