import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import Header from "common/components/Header/Header";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import FilteredList from "common/components/Table/FilteredList";
import AlertsContext from "common/providers/alerts";
import UserSearch from "components/search/UserSearch";
import ButtonGroup from "common/components/ButtonGroup/ButtonGroup";
import { Report } from "types/Report";
import EmergencyItem from "./EmergencyItem";

function Emergencies() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error: errorList1,
  } = useList<Report>("reports", {
    defaultSorts: ["createdAt:DESC"],
    populate: [
      "user",
      "user.avatar",
      "user.companyLogo",
      "author",
      "author.avatar",
      "author.companyLogo",
      "conversation",
      "conversation.seller",
      "conversation.buyer",
      "conversation.seller.avatar",
      "conversation.buyer.avatar",
      "conversation.finalMeetingPlace",
      "conversation.content",
      "conversation.content.images",
    ],
    defaultFilters: {
      isEmergency: true,
    },
    listenToEvents: ["new_emergency"],
    cachePrefix: "emergency",
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const getTypeContent: any = useCallback(
    (item: Report) => {
      // if (item.conversation) {
      //   return {
      //     name: t("common.conversation"),
      //     icon: "ChatBubbleLeftRightIcon",
      //     label: item.conversation.buyer.username,
      //   };
      // }
      if (item.content) {
        return {
          name: t("common.content"),
          icon: "CalendarDaysIcon",
          label: item.content.title,
        };
      }
      if (item.user) {
        return {
          name: t("common.user"),
          icon: "UserIcon",
          label: item.user.username,
        };
      }
      return null;
    },
    [t]
  );

  const { removeItem } = useItem<Report>("reports", "");

  const { navigate } = useRouter();

  const emergencies = useMemo(
    () =>
      items?.map((item) => ({
        ...item,
        typeContent: getTypeContent(item),
      })),
    [getTypeContent, items]
  );

  const handleNavigate = (id: any) => {
    navigate(`/emergencies/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      /* {
        Header: t("filters.status"),
        headerClasses: "hidden",
        columnClasses: "w-full mb-[-8px]", // mb-[-12px]
        filterName: "status$contains",
        filterComponent: (props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        ),
      }, */
      {
        Header: t("emergencies.author"),
        filterName: "author][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("emergencies.reportedContent"),
        filterName: "reportedContent][title]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.state"),
        filterName: "state",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <ButtonGroup
            className="w-full"
            options={[
              { value: null, label: t("status.all") },
              { value: "unprocessed", label: t("status.unprocessed") },
              { value: "processed", label: t("status.processed") },
            ]}
            {...props}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.emergencies")}
        Icon={ExclamationCircleIcon}
        subTitle={t("titleLegends.emergencies")}
        noAdd
        isBeforeFilters
      />

      <FilteredList
        data={emergencies ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={EmergencyItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Emergencies;
